import React from 'react';
import classNames from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {Snackbar, withStyles} from '@material-ui/core';
import {connect} from 'react-redux';

import * as actions from './actions';
import Slide from '@material-ui/core/Slide';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const contentStyle = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

const Content = withStyles(contentStyle)(props => {
    const {classes, className, message, onClose, variant, Icon, ...other} = props;
    return <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
            <span className={classes.message}>
                {Icon && <Icon className={classNames(classes.icon, classes.iconVariant)}/>}
                {message}
            </span>
        }
        action={[
            <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={onClose}
            >
                <CloseIcon className={classes.icon}/>
            </IconButton>,
        ]}
        {...other}
    />
});

const providerStyle = {
    snackbar: {
        transition: 'top 225ms cubic-bezier(0, 0, 0.2, 1) 225ms'
    }
};

const Transition = (props) => <Slide {...props} direction="left"/>;

const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
};
const Provider = withStyles(providerStyle)(props => {
    const topBase = window.document.body.clientWidth < 960 ? 0 : 24;
    let index = 0;
    return props.snackbar.map(item => {
        return (
            <Snackbar
                key={item.key}
                open={item.open}
                anchorOrigin={anchorOrigin}
                autoHideDuration={item.autoHideDuration}
                onClose={(e, reason) => props.hideSnackbar(item.key, reason)}
                style={{top: `${topBase + (item.open ? 72 * index++ : 0)}px`}}
                className={props.classes.snackbar}
                TransitionComponent={Transition}
            >
                <Content
                    onClose={() => props.hideSnackbar(item.key)}
                    message={item.message}
                    variant={item.type}
                    Icon={variantIcon[item.type]}
                />
            </Snackbar>
        );
    })
});

export default connect(state => state.frameworks, actions)(Provider)
