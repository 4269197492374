const update = (payload) => ({type: 'state.grant', payload});

export const getPermission = (cache = true) => (dispatch, getState, {api}) => {
    const {grant: {permission}} = getState();
    if (cache && permission && permission.list.length) {
        return Promise.resolve(permission);
    }
    return api.canGrant()
        .then(({data: permission}) => {
            dispatch(update({permission}));
            return permission;
        });
};
