const initialData = {
    rows: [],
    total: 0,
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    search: '',
    searchActive: false
};

const reducer = (state = initialData, {type, payload}) => {
    if (type === 'state.grantList') {
        return {...state, ...payload};
    }
    return state;
};

export default reducer;
