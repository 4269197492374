import {push} from 'connected-react-router';
import {showSnackbar} from '../../../frameworks/snackbar/actions';

const update = payload => ({type: 'state.devicesList', payload});

export const getData = (params) => (dispatch, getState, {api}) => {
    if (params) {
        dispatch(update(params));
    }
    const {page, rowsPerPage, order, type} = getState().devicesList;
    api.devicesList(page * rowsPerPage, rowsPerPage, order, type)
        .then(({data}) => {
            const {rows = [], active = {}} = data;
            rows.forEach(item => item.status = active[item.id] ? 1 : 0);
            dispatch(update(data))
        });
};

export const onChangePage = (_, page) => getData({page});
export const onChangeRowsPerPage = (e) => getData({rowsPerPage: e.target.value});

export const toDetail = (id) => push('/manage/devices/' + id);

export const open = (id) => (dispatch, getState, {api}) => {
    api.open(id)
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(showSnackbar({message: '操作成功', type: 'success'}));
            } else {
                dispatch(showSnackbar({
                    message: `${data.message || JSON.stringify(data)}`,
                    timeout: null,
                    type: 'warning'
                }));
            }
        })
        .catch((e) => {
            console.log(e);
        })
};
