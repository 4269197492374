const initialData = {};

const operate = (state = initialData, {type, payload}) => {
    if (type === 'state.devices.operate') {
        return {...state, ...payload};
    }
    return state;
};

export default operate;
