import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

const CustomTable = (props) => {
    const {classes = {}, config, data} = props;
    return (
        <>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {config.columns.map(
                            item => item.visible !== false &&
                                <TableCell key={item.field}>{item.title}</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.length ?
                        data.map(row => (
                            <TableRow key={row.id} hover onClick={props.onRowClick && (() => props.onRowClick(row.id))}>
                                {config.columns.map(
                                    item => item.visible !== false &&
                                        <TableCell key={item.field}>
                                            {item.formatter ? item.formatter(row[item.field], row, props) : row[item.field]}
                                        </TableCell>
                                )}
                            </TableRow>
                        )) :
                        <TableRow>
                            <TableCell
                                align={'center'}
                                colSpan={config.columns.filter(item => item.visible !== false).length}
                            >
                                {config.emptyDataSourceMessage || 'No records to display'}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
                labelRowsPerPage="每页行数:"
                labelDisplayedRows={({from, to, count}) => `显示第 ${from} - 第 ${to} 条记录，总共 ${count} 条记录`}
            />
        </>
    );
};

export default CustomTable;
