import {replace} from 'connected-react-router';
import {showSnackbar} from './snackbar/actions';

export const loadUserInfo = () => (dispatch, getState, {api}) => {
    api.getUserInfo()
        .then(({data}) => {
            if (data.message) {
                if (data.response && data.response.status === 403) {
                    dispatch(replace('/passport/login'));
                } else {
                    dispatch(showSnackbar({
                        message: data.message,
                        type: 'warning'
                    }));
                }
                return;
            }
            dispatch({type: 'state.frameworks', payload: {user: data}});
        });
};
