import React from 'react';
import OverviewOperate from '../Devices/Operate/OverviewOperate';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core';

const styles = theme => ({
    operate: {display: 'flex', flexWrap: 'wrap'},
});

const Main = (props) => <div>
    欢迎回来，{props.name}，这是您今天第一次登录
    <div className={props.classes.operate}>
        <OverviewOperate type={'door'}/>
        <OverviewOperate type={'light'}/>
    </div>
</div>;

const ManageIndex = connect(state => state.frameworks.user)(withStyles(styles)(Main));

export default ManageIndex;
