import React from 'react';
import {Route, Switch, Router} from 'react-router-dom'
import Manage from './Manage';
import Login from '../passport/Login';

const router = (props) => (
    <Router history={props.history}>
        <Switch>
            <Route path='/manage' component={Manage}/>
            <Route path='/passport' component={Login}/>
        </Switch>
    </Router>
);

export default router;
