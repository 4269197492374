import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import * as actions from './actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LocalizedUtils from '../../../util/LocalizedUtils';
import {DateTimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';

const GrantAdd = (props) => {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.hideDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">添加授权</DialogTitle>
                <DialogContent>
                    <FormControl
                        margin="dense"
                        fullWidth
                    >
                        <InputLabel>权限</InputLabel>
                        <Select value={props.form.code} onChange={props.onCodeSelected}>
                            <MenuItem value=""><em>请选择</em></MenuItem>
                            {props.permission.list.map(item =>
                                <MenuItem value={item.code} key={item.code}>{item.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="设备id"
                        type="text"
                        fullWidth
                        value={props.form.params}
                        onChange={props.onTextChange}
                        inputProps={{'data-id': 'params'}}
                    />
                    <TextField
                        margin="dense"
                        label="用户"
                        type="text"
                        fullWidth
                        value={props.user.selected}
                        disabled
                    />
                    <MuiPickersUtilsProvider
                        utils={LocalizedUtils}
                    >
                        <DateTimePicker
                            margin="dense"
                            label="起始日期"
                            fullWidth
                            ampm={false}
                            autoOk
                            value={props.form.startTime}
                            onChange={props.onStartTimeChange}
                        />
                        <DateTimePicker
                            margin="dense"
                            label="结束日期"
                            fullWidth
                            ampm={false}
                            autoOk
                            value={props.form.stopTime}
                            onChange={props.onStopTimeChange}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.hideDialog} color="default">
                        取消
                    </Button>
                    <Button onClick={props.submit} color="primary">
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect(({grantAdd, grant: {permission}}) => ({...grantAdd, permission}), actions)(GrantAdd);
