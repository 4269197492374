import axios from 'axios';

const request = axios.create({baseURL: '/api'});

request.interceptors.response.use(res => res, error => {
    console.log(error);
    return {data: error};
});

export default request;

export const basicLogin = (loginName, password) => request.post('/passport/basic', {loginName, password});

export const oauthLogin = (oauth_token, oauth_verifier) => request.post('/passport/oauth', {
    oauth_token,
    oauth_verifier
});

export const userList = (offset, limit, order) => request.get('/users/list', {params: {offset, limit, order}});

export const userDetail = (id) => request.get('/users/' + id);

export const userEdit = (id, data) => request.patch('/users/' + id, data);

export const userPermission = (id, {offset, limit, order}) =>
    request.get(`/users/${id}/permission`, {params: {offset, limit, order}});

export const devicesList = (offset, limit, order, type) => request.get('/devices/list/detail', {
    params: {
        offset,
        limit,
        order,
        type
    }
});

export const devicesDetail = (id) => request.get('/devices/' + id);

export const devicesEdit = (id, data) => request.patch('/devices/' + id, data);

export const open = (id) => request.post(`/devices/${id}/open`);

export const operateDevices = (id, operate) => request.post(`/devices/${id}/${operate}`);

export const canGrant = () => request.get('/permission/canGrant');

export const grant = (userId, data) => request.post(`/users/${userId}/permission`, data);

export const searchUser = (search) => request.get(`/users/search/${search}`);

export const getUserInfo = () => request.get('/users/info');

export const canOperate = (type) => request.get(`/devices/canOperate?type=${type}`);

export const addUser = (data) => request.post('/users', data);

export const revokePermission = (id) => request.delete(`/permission/${id}`);
