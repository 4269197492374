const initialData = {
    form: {
        code: '',
        params: '1',
        startTime: null,
        stopTime: null
    },
    open: false,
    user: {},
};

const reducer = (state = initialData, {type, payload}) => {
    switch (type) {
        case 'state.grantAdd':
            return {...state, ...payload};
        case 'state.grantAdd.form':
            return {...state, form: {...state.form, ...payload}};
        default:
    }
    return state;
};

export default reducer;
