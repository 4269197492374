import {showSnackbar} from '../../../../frameworks/snackbar/actions';

export const operate = (id, operate) => (dispatch, getState, {api}) => {
    const {permission: {devicesAdmin = [], devicesUser = []}} = getState().frameworks.user;
    if (devicesAdmin.includes('*') || devicesAdmin.includes(id) || devicesUser.includes('*') || devicesUser.includes(id)) {
        api.operateDevices(id, operate)
            .then(({data}) => {
                if (data.message === 'success') {
                    dispatch(showSnackbar({message: '操作成功', type: 'success'}));
                } else {
                    dispatch(showSnackbar({
                        message: `${data.message || JSON.stringify(data)}`,
                        timeout: null,
                        type: 'warning'
                    }));
                }
            })
    } else {
        dispatch(showSnackbar({
            message: '没有操作权限',
            type: 'warning'
        }));
    }
};
