import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {devicesType, didMountEnhancer} from '../../../util';
import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/Save';
import Clear from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import {connect} from 'react-redux';
import * as actions from './actions';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import GrantView from '../../Grant/View';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    title: {
        flex: '0 0 auto'
    },
    paper: {
        marginBottom: theme.spacing.unit * 3
    },
    spacer: {
        flex: '1 1 100%'
    }
});

const TextFieldStyle = (theme) => ({
    field: {
        display: 'flex',
        margin: theme.spacing.unit,
        alignItems: 'center'
    },
    fieldLabel: {
        margin: theme.spacing.unit,
        minWidth: '45px'
    },
    noUnderline: {
        '&:before': {
            borderBottom: '1px solid #fff'
        }
    },
    fixColor: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    hidden: {
        visibility: 'hidden'
    }
});

const TextField = withStyles(TextFieldStyle)(
    ({classes, title, field, value, editValue, locked, onChange, onSave, onCancel, error}) => (
        <div className={classes.field}>
            <Typography className={classes.fieldLabel}>{title}</Typography>
            <FormControl error={!!error}>
                <Input
                    onKeyDown={(e) => e.keyCode === 13 && onSave(field)}
                    value={editValue || value || ''}
                    classes={{underline: classes.noUnderline, disabled: classes.fixColor}}
                    disabled={locked}
                    onChange={(e) => onChange && onChange(e.target.value, field)}
                    endAdornment={
                        <InputAdornment position="end"
                                        className={(!editValue || value === editValue) ? classes.hidden : ''}>
                            <IconButton onClick={() => onSave(field)}>
                                <Save fontSize="small"/>
                            </IconButton>
                            <IconButton onClick={() => onCancel(field)}>
                                <Clear fontSize="small"/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </div>
    )
);

const pageConfig = [
    {field: 'id', title: 'ID'},
    {field: 'displayName', title: '设备名称', editable: true},
    {
        field: 'createTime',
        title: '创建时间',
        formatter: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        field: 'type',
        title: '设备类型',
        formatter: value => devicesType[value]
    },
    {field: 'status', title: '设备状态'},
    {field: 'action', title: '操作'}
];

const detail = (props) => {
    const {classes, data, editData, errorData} = props;
    return (
        <>
            <Paper className={classes.paper}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        设备信息
                    </Typography>
                </Toolbar>
                {data.id === props.match.params.id && <div className={classes.container}>
                    {pageConfig.map(item =>
                        <TextField
                            key={item.field}
                            field={item.field}
                            title={item.title}
                            value={item.formatter ? item.formatter(data[item.field]) : data[item.field]}
                            editValue={editData[item.field]}
                            error={errorData[item.field]}
                            locked={!item.editable}
                            onChange={props.updateField}
                            onSave={props.onFieldSave}
                            onCancel={props.onFieldCancel}
                        />
                    )}
                </div>}
            </Paper>
            <Paper className={classes.paper}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        设备权限
                    </Typography>
                    <div className={classes.spacer}/>
                    <Tooltip title="Add">
                        <IconButton>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <GrantView dataUrl={`/devices/${props.match.params.id}/permission`}
                           key={`devices-${props.match.params.id}`}/>
            </Paper>
        </>
    );
};

const componentDidMount = function () {
    this.props.getData(this.props.match.params.id);
};

export default connect(state => state.devicesDetail, actions)(withStyles(styles)(didMountEnhancer(detail, componentDidMount)));
