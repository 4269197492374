const initialData = {
    drawerMobileOpen: false,
    snackbar: [],
    user: {},
};

const reducer = (state = initialData, {type, payload}) => {
    if (type === 'state.frameworks') {
        return {...state, ...payload};
    }
    return state;
};

export default reducer;
