import {getPermission} from '../actions';
import {showSnackbar} from '../../../frameworks/snackbar/actions';

const update = payload => ({type: 'state.grantList', payload});

export const getData = (params) => (dispatch, getState, {request}) => {
    if (params) {
        dispatch(update(params));
    }
    const {page, rowsPerPage: limit, order, url} = getState().grantList;
    request.get(url, {params: {offset: page * limit, limit, order}})
        .then(({data}) => {
            // const {rows = [], active = {}} = data;
            // rows.forEach(item => item.status = active[item.id] ? 1 : 0);
            dispatch(update(data));
        });
};

export const revoke = ({id, code, params}) => (dispatch, getState, {api}) => {
    dispatch(getPermission())
        .then(permission => {
            const permissionData = permission.detail[code];
            console.log(permissionData, permission, code);
            if (permissionData && (permissionData.includes(params) || permissionData.includes('*'))) {
                console.log(id, permission, code, params);
                api.revokePermission(id)
                    .then(({data}) => {
                        if (data.message === 'success' || data.message === 'not modified') {
                            dispatch(showSnackbar({message: '操作成功', type: 'success'}));
                        } else {
                            dispatch(showSnackbar({
                                message: `${data.message || JSON.stringify(data)}`,
                                timeout: null,
                                type: 'warning'
                            }));
                        }
                    });
            } else {
                dispatch(showSnackbar({message: '没有权限', type: 'warning'}));
            }
        });
};

export const onChangePage = (_, page) => getData({page});
export const onChangeRowsPerPage = (e) => getData({rowsPerPage: e.target.value});
