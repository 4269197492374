import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router'
import store, {history} from './store/';
import Router from './routes';
import 'typeface-roboto';
import SnackbarProvider from './frameworks/snackbar/SnackbarProvider';
import * as moment from 'moment';

moment.locale('zh-cn');

const App = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Router history={history}/>
        </ConnectedRouter>
        <SnackbarProvider/>
    </Provider>
);

export default App;
