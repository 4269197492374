import React from 'react';
import Card from '@material-ui/core/Card/index';
import Operate from '../Operate';
import CardHeader from '@material-ui/core/CardHeader/index';
import {devicesType} from '../../../../util';
import Typography from '@material-ui/core/Typography/index';
import {connect} from 'react-redux';
import {getOperateList} from './actions';
import {withStyles} from '@material-ui/core';

const styles = theme => ({
    operateView: {flex: '1', margin: theme.spacing.unit, minWidth: '300px'},
    devicesList: {display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'},
    devicesItem: {width: '300px', margin: theme.spacing.unit},
    devicesItemContent: {overflow: 'hidden', textOverflow: 'ellipsis'}
});

const OperateCard = (props) => (
    <Card className={props.className}>
        <CardHeader
            title={props.displayName}
            classes={{content: props.classes.devicesItemContent, title: props.classes.devicesItemContent}}
            action={<Operate {...props}/>}
        />
    </Card>
);

class Main extends React.Component {
    componentDidMount() {
        this.props.getOperateList(this.props.type);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.type !== prevProps.type) {
            this.props.getOperateList(this.props.type);
        }
    }

    render() {
        const {props} = this;
        return (<div className={props.classes.operateView}>
            <Typography variant='h6'>{devicesType[props.type]}设备列表</Typography>
            <div className={props.classes.devicesList}>
                {props.data && Object.keys(props.data).length ?
                    Object.keys(props.data)
                        .map(id =>
                            <OperateCard className={props.classes.devicesItem} classes={props.classes} id={id}
                                         key={id} {...props.data[id]}/>) :
                    <Typography>暂无{devicesType[props.type]}设备</Typography>
                }
            </div>
        </div>);
    }
}

const OverviewOperate = connect((state, props) => ({data: state.devices.operate[props.type]}), {getOperateList})(withStyles(styles)(Main));

export default OverviewOperate;

