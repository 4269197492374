const initialData = {
    data: {},
    editData: {},
    errorData: {}
};

const reducer = (state = initialData, {type, payload}) => {
    switch (type) {
        case 'state.devicesDetail':
            return {...state, ...payload};
        case 'state.devicesDetail.data':
            return {...state, data: {...state.data, ...payload}};
        case 'state.devicesDetail.editData':
            return {...state, editData: {...state.editData, ...payload}};
        case 'state.devicesDetail.errorData':
            return {...state, errorData: {...state.errorData, ...payload}};
        default:
    }
    return state;
};

export default reducer;
