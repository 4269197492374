import React from 'react';

export const didMountEnhancer = (Component, componentDidMount) => (
    class didMountEnhancer extends React.Component {
        componentDidMount() {
            componentDidMount.call(this);
        }

        render() {
            return <Component {...this.props}/>
        }
    }
);

export const devicesType = {
    door: '门禁',
    light: '照明'
};
