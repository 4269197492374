import qs from 'querystring';
import {push} from 'connected-react-router';
import {showSnackbar} from '../frameworks/snackbar/actions';

const update = payload => ({type: 'state.passport', payload});
export const updateLoginName = (e) => {
    return update({loginName: e.target.value, loginNameError: ''});
};
export const updatePassword = (e) => {
    return update({password: e.target.value, passwordError: ''});
};

const handleLoginResult = ({data: {message, user}}) => dispatch => {
    dispatch(update({loading: false}));
    switch (message) {
        case 'loginName error':
            return dispatch(update({loginNameError: '用户名错误'}));
        case 'password error':
            return dispatch(update({passwordError: '密码错误'}));
        default:
            break;
        case undefined:
            console.log(user);
            dispatch({type: 'state.framework', payload: {user}});
            dispatch(push('/manage/'));
            break;
    }
};

export const basicLogin = () => (dispatch, getState, {api}) => {
    const {passport} = getState();
    if (!passport.loginName) {
        return dispatch(update({loginNameError: '请输入用户名'}));
    }
    if (!passport.password) {
        return dispatch(update({passwordError: '请输入密码'}));
    }
    dispatch(update({loginNameError: '', passwordError: '', loading: true}));
    return api.basicLogin(passport.loginName, passport.password)
        .then(data => dispatch(handleLoginResult(data)));
};

export const toOauthLogin = () => (dispatch, getState, {api}) => {
    dispatch(update({loading: true}));
    api.oauthLogin().then(({data}) => {
        const {authorizeUrl, message} = data;
        if (!authorizeUrl) {
            dispatch(showSnackbar({message, type: 'warning'}));
            return dispatch(update({loading: false}));
        }
        window.location.hash = 'oauthLogin';
        window.location.href = authorizeUrl;
    });
};

export const handleHash = (hash) => (dispatch, getState, {api}) => {
    if (hash.length > 30) {
        const {oauth_token, oauth_verifier} = qs.parse(hash.substr(1));
        if (oauth_token && oauth_verifier) {
            api.oauthLogin(oauth_token, oauth_verifier)
                .then(data => dispatch(handleLoginResult(data)));
        }
    } else if (hash === '#oauthLogin') {
        dispatch(showSnackbar({message: '授权取消', type: 'warning'}));
    }
};
