import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom';

const DrawerContent = ({classes, permission = {}}) => (
    <div>
        <div className={classes.toolbar}/>
        <Divider/>
        <List>
            <ListItem component={Link} to="/manage/" button className={classes.nested}>
                <ListItemText primary="主页"/>
            </ListItem>
        </List>
        {(permission.devicesAdmin || permission.devicesUser) && <>
            <Divider/>
            <List>
                <ListItem button>
                    <ListItemText primary="门禁系统"/>
                </ListItem>
                <Collapse in>
                    <List>
                        <ListItem component={Link} to="/manage/devices/door/operate" button className={classes.nested}>
                            <ListItemText primary="操作设备"/>
                        </ListItem>
                        {permission.devicesAdmin &&
                        <ListItem component={Link} to="/manage/devices/door/list" button className={classes.nested}>
                            <ListItemText primary="管理设备"/>
                        </ListItem>
                        }
                    </List>
                </Collapse>
            </List>
            <Divider/>
            <List>
                <ListItem button>
                    <ListItemText primary="照明系统"/>
                </ListItem>
                <Collapse in>
                    <List>
                        <ListItem component={Link} to="/manage/devices/light/operate" button className={classes.nested}>
                            <ListItemText primary="操作设备"/>
                        </ListItem>
                        {permission.devicesAdmin &&
                        <ListItem component={Link} to="/manage/devices/light/list" button className={classes.nested}>
                            <ListItemText primary="管理设备"/>
                        </ListItem>
                        }
                    </List>
                </Collapse>
            </List>
        </>}
        {permission.userAdmin && <>
            <Divider/>
            <List>
                <ListItem button>
                    <ListItemText primary="用户管理"/>
                </ListItem>
                <Collapse in>
                    <List>
                        <ListItem component={Link} to="/manage/users" button className={classes.nested}>
                            <ListItemText primary="用户列表"/>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </>}
    </div>
);

export default DrawerContent;
