const initialData = {
    form: {
        loginName: '',
        name: '',
        password: '',
        telephone: ''
    },
    from: ''
};

const add = (state = initialData, {type, payload}) => {
    if (type === 'state.users.add') {
        return {...state, ...payload};
    }
    if (type === 'state.users.add.form') {
        return {...state, form: {...state.form, ...payload}};
    }
    if (type === 'state.users.add.reset') {
        return initialData;
    }
    return state;
};

export default add;
