import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import * as actions from './actions';

const fields = [
    {field: 'loginName', title: '登录名'},
    {field: 'name', title: '姓名'},
    {field: 'password', title: '密码', type: 'password'},
    {field: 'telephone', title: '电话号码'}
];

const Add = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.hideDialog}
            aria-labelledby="add-user"
        >
            <DialogTitle id="add-user">添加用户</DialogTitle>
            <DialogContent>
                {fields.map(item => (
                    <TextField
                        key={item.field}
                        margin="dense"
                        label={item.title}
                        type={item.type || 'text'}
                        fullWidth
                        value={props.form[item.field]}
                        onChange={props.onTextChange}
                        inputProps={{'data-key': item.field}}
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.hideDialog} color="default">
                    取消
                </Button>
                <Button onClick={props.submit} color="primary">
                    确认
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const UserAdd = connect(state => state.users.add, actions)(Add);

export default UserAdd;
