import {getPermission} from '../actions';
import {showSnackbar} from '../../../frameworks/snackbar/actions';

const update = payload => ({type: 'state.grantAdd', payload});

const updateForm = payload => ({type: 'state.grantAdd.form', payload});

export const hideDialog = () => update({open: false});

export const showDialog = () => (dispatch, getState) => {
    const {id: selected} = getState().userDetail.userData;
    dispatch(update({open: true, user: {selected}}));
    dispatch(getPermission());
};

export const onCodeSelected = (e) => updateForm({code: e.target.value});

export const onStartTimeChange = (t) => updateForm({startTime: t.valueOf()});

export const onStopTimeChange = (t) => updateForm({stopTime: t.valueOf()});

export const onTextChange = (e) => updateForm({[e.target.getAttribute('data-id')]: e.target.value});

export const submit = () => (dispatch, getState, {api}) => {
    const {user: {selected: userId}, form} = getState().grantAdd;
    api.grant(userId, form)
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(showSnackbar({message: '操作成功', type: 'success'}));
                dispatch(hideDialog());
            } else {
                dispatch(showSnackbar({
                    message: `${data.message || JSON.stringify(data)}`,
                    timeout: null,
                    type: 'warning'
                }));
            }
        });
};
