import * as moment from 'moment';
import 'moment/locale/zh-cn';
import MomentUtils from '@date-io/moment';

moment.locale('zh-cn');

export default class LocalizedUtils extends MomentUtils {
    constructor(props) {
        super(props);
        this.dateTime24hFormat = "YYYY-MM-DD HH:mm";
    }

    getDateTimePickerHeaderText(date) {
        return date.format("MMM Do");
    }
}
