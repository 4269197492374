export const showSnackbar = ({message, timeout = 3000, type}) => (dispatch, getState) => {
    const {snackbar} = getState().frameworks;
    const newBar = {key: Date.now(), message, type, open: true};
    if (timeout) newBar.autoHideDuration = timeout;
    dispatch({
        type: 'state.frameworks',
        payload: {
            snackbar: snackbar.concat(newBar)
        }
    })
};

export const hideSnackbar = (key, reason) => (dispatch, getState) => {
    if (reason === 'clickaway') {
        return;
    }
    const {snackbar} = getState().frameworks;
    let index = -1;
    for (let i = 0; i < snackbar.length; i++) {
        if (snackbar[i].key === key) {
            index = i;
            snackbar[i].open = false;
            break;
        }
    }
    if (index !== -1) {
        dispatch({
            type: 'state.frameworks',
            payload: {
                snackbar: [...snackbar]
            }
        });
        setTimeout(() => dispatch(deleteSnackbar(key)), 300);
    }
};

export const deleteSnackbar = key => (dispatch, getState) => {
    const {snackbar} = getState().frameworks;
    let index = -1;
    for (let i = 0; i < snackbar.length; i++) {
        if (snackbar[i].key === key) {
            index = i;
            break;
        }
    }
    if (index !== -1) {
        snackbar.splice(index, 1);
        dispatch({
            type: 'state.frameworks',
            payload: {snackbar}
        });
    }
};
