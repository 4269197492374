const initialData = {
    userData: {},
    editData: {},
    errorData: {}
};

const reducer = (state = initialData, {type, payload}) => {
    switch (type) {
        case 'state.userDetail':
            return {...state, ...payload};
        case 'state.userDetail.edit':
            return {...state, editData: {...state.editData, ...payload}};
        case 'state.userDetail.errorData':
            return {...state, errorData: {...state.errorData, ...payload}};
        case 'state.userDetail.userData':
            return {...state, userData: {...state.userData, ...payload}};
        default:
    }
    return state;
};

export default reducer;
