import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import frameworks from './frameworks';
import passport from './passport';
import userList from './userList';
import userDetail from './userDetail';
import devicesList from './devicesList';
import devicesDetail from './devicesDetail';
import grantList from './grantList';
import grantAdd from './grantAdd';
import devices from './devices';
import users from './users';
import grant from './grant';

export default (history) => combineReducers({
    frameworks,
    passport,
    userList,
    userDetail,
    devicesList,
    devicesDetail,
    grantList,
    grantAdd,
    devices,
    users,
    grant,
    router: connectRouter(history),
})
