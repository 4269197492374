import React from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import * as actions from './actions';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
    },
    container: {
        padding: '44px',
        width: 'calc(100% - 40px)',
        textAlign: 'center',
        marginBottom: '50px',
        minWidth: '320px',
        maxWidth: '440px',
        minHeight: '338px'
    },
    textField: {
        width: '100%'
    },
    button: {
        marginTop: '35px'
    },
    progress: {
        marginRight: theme.spacing.unit,
    }
});

class LoginComponent extends React.Component {
    componentDidMount() {
        const hash = this.props.location.hash;
        if (hash && hash.length) {
            this.props.handleHash(hash);
        }
    }

    render() {
        const {classes = {}, ...props} = this.props;
        return <div className={classes.root}>
            <CssBaseline/>
            <Paper className={classes.container}>
                <form noValidate autoComplete="off">
                    <TextField
                        label={props.loginNameError || '用户名'}
                        placeholder="请输入用户名"
                        className={classes.textField}
                        margin="normal"
                        value={props.loginName}
                        onChange={props.updateLoginName}
                        error={!!props.loginNameError}
                    />
                    <TextField
                        label={props.passwordError || '密码'}
                        placeholder="请输入密码"
                        className={classes.textField}
                        margin="normal"
                        type="password"
                        value={props.password}
                        onChange={props.updatePassword}
                        error={!!props.passwordError}
                    />
                    <Button className={classes.button} variant="contained" color="primary" disabled={props.loading}
                            onClick={props.basicLogin}>
                        {props.loading && <CircularProgress size={20} className={classes.progress}/>}登录
                    </Button>
                    <Button className={classes.button} variant="contained" color="primary" disabled={props.loading}
                            onClick={props.toOauthLogin}>
                        {props.loading && <CircularProgress size={20} className={classes.progress}/>}oauth认证
                    </Button>
                </form>
            </Paper>
        </div>;
    }
}

const Login = connect(state => state.passport, actions)(withStyles(styles)(LoginComponent));

export default Login;
