import {push} from 'connected-react-router';

const update = payload => ({type: 'state.userList', payload});

export const getUserList = () => (dispatch, getState, {api}) => {
    const {page, rowsPerPage, order} = getState().userList;
    api.userList(page * rowsPerPage, rowsPerPage, order)
        .then(({data}) => dispatch(update(data)));
};

export const onChangePage = (_, page) => (dispatch) => {
    dispatch(update({page}));
    dispatch(getUserList());
};
export const onChangeRowsPerPage = (e) => (dispatch) => {
    dispatch(update({rowsPerPage: e.target.value}));
    dispatch(getUserList());
};

export const showSearch = (e) => {
    return update({searchActive: true});
};

export const searchBlur = () => (dispatch, getState) => {
    const {search, searchActive} = getState().userList;
    if (searchActive && search.length === 0) {
        dispatch(update({searchActive: false}))
    }
};

export const searchClick = () => (dispatch, getState) => {
    dispatch(update({searchActive: !getState().userList.searchActive}));
};

export const toDetail = (id) => push('/manage/users/' + id);

export const userAdd = () => ({type: 'state.users.add', payload: {from: 'userList'}});
