import React from 'react';
import {Route, Switch} from 'react-router-dom'
import ManagerLayout from '../frameworks/ManageLayout';
import Index from '../manage/Index';
import Users from '../manage/Users';
import UserDetail from '../manage/UserDetail';
import DevicesList from '../manage/Devices/List';
import DevicesDetail from '../manage/Devices/Detail';
import DevicesOperate from '../manage/Devices/Operate';

const Manage = (props) => (
    <ManagerLayout {...props}>
        <Switch>
            <Route exact path={'/manage/users'} component={Users}/>
            <Route exact path={'/manage/users/add'} component={Users}/>
            <Route exact path={'/manage/users/:id'} component={UserDetail}/>
            <Route exact path={'/manage/devices/:type/list'} component={DevicesList}/>
            <Route exact path={'/manage/devices/:type/operate'} component={DevicesOperate}/>
            <Route exact path={'/manage/devices/:id'} component={DevicesDetail}/>
            <Route component={Index}/>
        </Switch>
    </ManagerLayout>
);

export default Manage;
