const update = payload => ({type: 'state.userDetail', payload});

const updateData = payload => ({type: 'state.userDetail.userData', payload});

const edit = payload => ({type: 'state.userDetail.edit', payload});

const updateError = payload => ({type: 'state.userDetail.errorData', payload});

export const loadUser = (id) => (dispatch, getState, {api}) => {
    api.userDetail(id).then(({data}) => {
        if (data.message) {

        } else {
            dispatch(update({userData: data}));
        }
    });
};

export const updateField = (value, field) => {
    return edit({[field]: value});
};

export const onFieldSave = (field) => (dispatch, getState, {api}) => {
    const {userData, editData} = getState().userDetail;
    api.userEdit(userData.id, {[field]: editData[field]})
        .then(({data}) => {
            if (data.message === 'success' || data.message === 'not modified') {
                dispatch(updateData({[field]: editData[field]}));
                dispatch(edit({[field]: undefined}));
                dispatch(updateError({[field]: undefined}))
            } else {
                dispatch(updateError({[field]: data.message || data}));
            }
        })
        .catch(err => dispatch(updateError({[field]: err.message || err})));
    return update({});
};

export const onFieldCancel = (field) => dispatch => {
    dispatch(edit({[field]: ''}));
    dispatch(updateError({[field]: undefined}));
};
