const initialData = {
    loginName: '',
    loginNameError: '',
    password: '',
    passwordError: '',
    loading: false
};

const reducer = (state = initialData, {type, payload}) => {
    if (type === 'state.passport') {
        return {...state, ...payload};
    }
    return state;
};

export default reducer;
