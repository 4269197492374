import React from 'react';
import CustomTable from '../../../frameworks/CustomTable';
import * as actions from './actions';
import moment from 'moment';
import {connect} from 'react-redux';
import {didMountEnhancer} from '../../../util';
import Block from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton';

const tableConfig = {
    columns: [
        {field: 'id', title: 'ID', visible: false},
        {field: 'permissionName', title: '权限名'},
        {field: 'userName', title: '姓名'},
        {
            field: 'createTime',
            title: '创建时间',
            formatter: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            field: 'startTime',
            title: '生效时间',
            formatter: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            field: 'stopTime',
            title: '过期时间',
            formatter: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {field: 'status', title: '状态', formatter: value => value === 1 ? '正常' : '禁用'},
        {
            field: 'action',
            title: '操作',
            formatter: (value, row, props) => (
                row.status === 1 && <IconButton
                    onClick={() => {
                        props.revoke(row);
                    }}
                >
                    <Block/>
                </IconButton>
            )
        }
    ]
};

const GrantView = (props) => {
    const {classes} = props;
    return <CustomTable
        classes={classes}
        config={tableConfig}
        data={props.rows}
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={props.onChangePage}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        revoke={props.revoke}
    />;
};

const componentDidMount = function () {
    this.props.getData({url: this.props.dataUrl});
};

export default connect(state => state.grantList, actions)(didMountEnhancer(GrantView, componentDidMount));
