import IconButton from '@material-ui/core/IconButton/index';
import VpnKey from '@material-ui/icons/VpnKey';
import BrightnessLow from '@material-ui/icons/BrightnessLow';
import BrightnessHigh from '@material-ui/icons/BrightnessHigh';
import React from 'react';
import {connect} from 'react-redux';
import {operate} from './actions';

const Operate = ({id, status, type, operate}) => {
    if (status === 0) {
        return null;
    }
    return <>
        {type === 'door' && <IconButton
            onClick={() => {
                operate(id, 'open');
            }}
        >
            <VpnKey/>
        </IconButton>}
        {type === 'light' && <IconButton
            onClick={() => {
                operate(id, 'off');
            }}
        >
            <BrightnessLow/>
        </IconButton>}
        {type === 'light' && <IconButton
            onClick={() => {
                operate(id, 'on');
            }}
        >
            <BrightnessHigh/>
        </IconButton>}
    </>;
};

export default connect(state => state.frameworks, {operate})(Operate);
