import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import CustomTable from '../../../frameworks/CustomTable';
import * as actions from './actions';
import {devicesType} from '../../../util';
import Operate from '../Operate/Operate';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row-reverse',
        transition: 'width .2s',
    },
    spacer: {
        flex: '1 1 100%',
    },
    title: {
        flex: '0 0 auto',
    },
});

const tableConfig = {
    columns: [
        {field: 'id', title: 'ID'},
        {field: 'devicesName', title: '设备名称'},
        {
            field: 'createTime',
            title: '创建时间',
            formatter: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            field: 'type',
            title: '设备类型',
            formatter: value => devicesType[value]
        },
        {field: 'status', title: '设备状态', formatter: value => value ? '在线' : '离线'},
        {
            field: 'action',
            title: '操作',
            formatter: (value, row) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <Operate {...row} />
                </div>
            )
        },
    ]
};

class DevicesList extends React.Component {
    componentDidMount() {
        this.props.getData(this.props.match.params);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params !== this.props.match.params) {
            this.props.getData(this.props.match.params);
        }
    }

    render() {
        const {props} = this;
        const {classes} = props;

        return (
            <Paper className={classes.root}>
                <Toolbar>
                    <div className={classes.title}>
                        <Typography variant="h6">
                            {devicesType[props.match.params.type]}设备列表
                        </Typography>
                    </div>
                    <div className={classes.spacer}/>
                    <div className={classes.actions}>
                    </div>
                </Toolbar>
                <CustomTable
                    classes={classes}
                    config={tableConfig}
                    data={props.rows}
                    count={props.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={props.onChangePage}
                    onChangeRowsPerPage={props.onChangeRowsPerPage}
                    onRowClick={props.toDetail}
                    open={props.open}
                />
            </Paper>
        );
    }
}

export default connect(state => state.devicesList, actions)(withStyles(styles)(DevicesList));
