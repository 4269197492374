import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// import Search from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';

import CustomTable from '../../frameworks/CustomTable';
import * as actions from './actions';
import {didMountEnhancer} from '../../util';
import UserAdd from './Add';
import ListItem from '@material-ui/core/ListItem';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 1000,
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row-reverse',
        transition: 'width .2s',
    },
    spacer: {
        flex: '1 1 100%',
    },
    title: {
        flex: '0 0 auto',
    },
    input: {
        transition: 'width .2s',
        width: 0,
    },
    inputActive: {
        width: 100,
    },
});

const tableConfig = {
    columns: [
        {field: 'id', title: 'ID'},
        {field: 'loginName', title: '登录名'},
        {field: 'name', title: '姓名'},
        {
            field: 'createTime',
            title: '创建时间',
            formatter: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {field: 'source', title: '来源'},
        {field: 'telephone', title: '电话号码'},
        {field: 'createUser', title: '创建者ID', visible: false},
        {field: 'createUserName', title: '创建者', formatter: value => value || '系统'},
    ]
};

const user = (props) => {
    const {classes} = props;

    console.log(props);
    return (
        <>
            <Paper className={classes.root} onClick={props.searchBlur}>
                <Toolbar>
                    <div className={classes.title}>
                        <Typography variant="h6">
                            用户列表
                        </Typography>
                    </div>
                    <div className={classes.spacer}/>
                    <div className={classes.actions} onMouseOver={props.showSearch}
                         onClick={(e) => e.stopPropagation()}>
                        <Tooltip title={'添加用户'}>
                            <IconButton component={Link} to="/manage/users/add" onClick={props.userAdd}>
                                <Add/>
                            </IconButton>
                        </Tooltip>
                        {/*<Tooltip title="Search">*/}
                        {/*    <IconButton aria-label="Filter list">*/}
                        {/*        <Search/>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        {/*<InputBase*/}
                        {/*    placeholder="用户名/姓名"*/}
                        {/*    classes={{*/}
                        {/*        root: props.searchActive ? `${classes.input} ${classes.inputActive}` : classes.input*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </div>
                </Toolbar>
                <CustomTable
                    classes={classes}
                    config={tableConfig}
                    data={props.rows}
                    count={props.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={props.onChangePage}
                    onChangeRowsPerPage={props.onChangeRowsPerPage}
                    onRowClick={props.toDetail}
                />
            </Paper>
            <UserAdd open={props.match.path.endsWith('/add')}/>
        </>
    );
};

const componentDidMount = function () {
    this.props.getUserList();
};

export default connect(state => state.userList, actions)(withStyles(styles)(didMountEnhancer(user, componentDidMount)));
