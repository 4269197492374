const update = payload => ({type: 'state.devicesDetail', payload});

const updateData = payload => ({type: 'state.devicesDetail.data', payload});

const updateEdit = payload => ({type: 'state.devicesDetail.editData', payload});

const updateError = payload => ({type: 'state.devicesDetail.errorData', payload});

export const getData = (id) => (dispatch, getState, {api}) => {
    api.devicesDetail(id).then(({data}) => {
        if (data.message) {

        } else {
            dispatch(update({data}));
        }
    });
};

export const updateField = (value, field) => {
    return updateEdit({[field]: value});
};

export const onFieldSave = (field) => (dispatch, getState, {api}) => {
    const {data, editData} = getState().devicesDetail;
    api.devicesEdit(data.id, {[field]: editData[field]})
        .then(({data}) => {
            if (data.message === 'success' || data.message === 'not modified') {
                dispatch(updateData({[field]: editData[field]}));
                dispatch(updateEdit({[field]: undefined}));
                dispatch(updateError({[field]: undefined}))
            } else {
                dispatch(updateError({[field]: data.message || data}));
            }
        })
        .catch(err => dispatch(updateError({[field]: err.message || err})));
};

export const onFieldCancel = (field) => dispatch => {
    const payload = {[field]: undefined};
    dispatch(updateEdit(payload));
    dispatch(updateError(payload));
};
