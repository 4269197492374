import {goBack, push} from 'connected-react-router';
import {showSnackbar} from '../../../frameworks/snackbar/actions';

const update = (payload) => ({type: 'state.users.add', payload});
const updateForm = (payload) => ({type: 'state.users.add.form', payload});

export const selector = state => state.users.add;

export const hideDialog = () => (dispatch, getState) => {
    const {from} = getState().users.add;
    if (from === 'userList') {
        dispatch(goBack());
    } else {
        dispatch(push('/manage/users'));
    }
    dispatch(update({from: ''}));
};

export const submit = () => (dispatch, getState, {api}) => {
    const {form} = selector(getState());
    api.addUser(form)
        .then(({data}) => {
            if (data.message === 'success') {
                dispatch(showSnackbar({message: '操作成功', type: 'success'}));
                dispatch(push(`/manage/users/${data.id}`));
            } else {
                dispatch(showSnackbar({
                    message: `${data.message || JSON.stringify(data)}`,
                    timeout: null,
                    type: 'warning'
                }));
            }
        });
};

export const onTextChange = (e) => updateForm({[e.target.getAttribute('data-key')]: e.target.value});
