import {applyMiddleware, compose, createStore} from "redux";
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import createReducer from './reducers/createReducer';
import request, * as api from '../api';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const store = createStore(
    createReducer(history),
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk.withExtraArgument({request, api})
            // ... other middlewares ...
        ),
    ),
);
export default store;
